*, ::after, ::before
    box-sizing border-box

html
    height 100%
    body
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto, Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif
        background white
        min-height 100%
        display flex
        flex-direction column
        width 100%
        font-size 13px
        span
            font-size 13px
        main
            flex 1

a, p, h1, h2, h3, h4, h4, h5
    color texts
    line-height: 1.3

a, p
    font-size: 15px
    color texts

i.fas, i.fab, i.far
    color texts2
i.fa-left
    margin-right 3px
i.fa-white
    color white

.center
    lost-center 1200px
    +below(1240px)
        padding 0 30px
    +below(768px)
        padding 0 15px

a
    text-decoration none
    color: #417bb3

.mobile
    display none

.clear
    clearfix()

.op
    opacity .95

.op2
    opacity .85
