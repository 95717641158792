@lost gutter 20px;
@lost cycle none;
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  height: 100%;
}
html body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
}
html body span {
  font-size: 13px;
}
html body main {
  flex: 1;
}
a,
p,
h1,
h2,
h3,
h4,
h5 {
  color: #292929;
  line-height: 1.3;
}
a,
p {
  font-size: 15px;
  color: #292929;
}
i.fas,
i.fab,
i.far {
  color: #666;
}
i.fa-left {
  margin-right: 3px;
}
i.fa-white {
  color: #fff;
}
.center {
  lost-center: 1200px;
}
@media only screen and (max-width: 1240px) {
  .center {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 768px) {
  .center {
    padding: 0 15px;
  }
}
a {
  text-decoration: none;
  color: #417bb3;
}
.mobile {
  display: none;
}
.clear {
  *zoom: 1;
}
.clear:before,
.clear:after {
  content: "";
  display: table;
}
.clear:after {
  clear: both;
}
.op {
  opacity: 0.95;
}
.op2 {
  opacity: 0.85;
}
.layout .dropdown-menu,
.layout .dropmenu-first {
  border: none;
}
@media only screen and (max-width: 1024px) {
  .layout main {
    padding-top: 20px;
  }
}
.layout .slice-cinza {
  background: #f3f3f3;
}
.layout .slice {
  padding-top: 20px;
  padding-bottom: 20px;
}
.layout .slice .subtitle {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 25px;
  color: #003f8f;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}
.layout ul.breadcrumbs {
  padding: 0;
  margin-bottom: 1em;
  color: #8ba3cf;
}
.layout ul.breadcrumbs li:not(:last-child):after {
  content: '>>';
}
.layout ul.breadcrumbs li:last-child {
  text-decoration: underline;
}
.layout ul.breadcrumbs li {
  display: inline-block;
  text-transform: uppercase;
}
.layout ul.breadcrumbs li i.fas {
  color: #8ba3cf;
}
.layout ul.breadcrumbs li a {
  font-size: 12px;
  color: #003f8f;
  text-transform: uppercase;
  text-decoration: none;
}
.layout .share-w {
  display: flex;
  align-items: center;
  padding: 22px 0 0;
}
.layout .share-w .share-txt,
.layout .share-w .share-icons {
  display: inline-block;
}
.layout .share-w .share-txt {
  margin-right: 15px;
  font-size: 15px;
}
.layout .share-w .share-icons .share-item {
  cursor: pointer;
}
.layout .share-w .share-icons .share-item i.fab,
.layout .share-w .share-icons .share-item i.fas {
  font-size: 34px;
}
.layout .share-w .share-icons .share-facebook i.fab {
  color: #3a5b9a;
}
.layout .share-w .share-icons .share-twitter i.fab {
  color: #55a4db;
}
.layout .share-w .share-icons .share-google-plus i.fab {
  color: #e04d41;
}
.layout .share-w .share-icons .share-mail i.fas {
  color: #033696;
}
.down-arrow {
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 6px;
  background: #8ba3cf;
  padding: 0;
  width: 10px;
  text-align: center;
}
.down-arrow:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid #8ba3cf;
  border-right: 6px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 6px solid transparent;
}
.data-block.data-warning {
  background-color: #fff0f0;
  color: #ee6067;
}
.data-block.data-warning p,
.data-block.data-warning p>strong {
  color: #ee6067;
}
.data-block.data-info {
  background-color: #f4faff;
  color: #0d7fcc;
}
.data-block.data-info p,
.data-block.data-info p>strong {
  color: #0d7fcc;
}
.data-block {
  position: relative;
  font-size: 14px;
  lost-center: 90%;
  max-width: 860px;
  border-radius: 4px;
  background-color: #fcf8e3;
  border: 1px solid #6d6d6d;
  padding: 23px 15px 24px 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.3);
}
.data-block ul {
  text-align: left;
  padding-left: 22px;
}
.data-block i.icon {
  display: inline-block;
  top: -4px;
  float: left;
}
.data-block .block-title {
  font-weight: normal;
  font-size: 16px;
  color: #ff3535;
  margin-bottom: 10px;
}
.data-block p {
  color: #717272;
  font-size: 14px;
  font-weight: lighter;
  margin: 0 0 0 auto;
  padding: 0 24px 0 35px;
}
.data-block a {
  background: #044a90;
  color: #fff;
}
.data-block .block-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  overflow: visible;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  color: #95979c;
  cursor: pointer;
  transition: color 0.2s;
  border: 0;
  outline: 0;
  background: transparent;
}
.data-block .block-close:before {
  position: absolute;
  font-size: 25px;
  line-height: 35px;
  top: 0;
  left: 0;
  display: block;
  width: 35px;
  content: "\00d7";
  text-align: center;
}
.btn {
  font-size: 13px;
}
.btn:focus {
  box-shadow: none !important;
}
.btn.btn-100 {
  width: 100%;
  margin: 0 0 11px 0;
}
.btn-primary {
  color: #fff;
  background-color: #003f8f;
  border-color: #003f8f;
}
.btn-primary:hover {
  background-color: #003272;
  border-color: #003f8f;
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #003f8f;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,90,90,0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #8ba3cf;
  border-color: #8ba3cf;
}
.btn-secondary:hover {
  background-color: #5a7cbb;
  border-color: #8ba3cf;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #8ba3cf;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,90,90,0.5);
}
.btn-tertiary {
  color: #fff;
  background-color: #23a7e2;
  border-color: #23a7e2;
}
.btn-tertiary:hover {
  background-color: #1887b8;
  border-color: #23a7e2;
}
.btn-tertiary:not(:disabled):not(.disabled):active {
  background-color: #23a7e2;
}
.btn-tertiary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,90,90,0.5);
}
.btn-g {
  color: #fff;
  background-color: #42ca49;
  border-color: #42ca49;
}
.btn-g:hover {
  background-color: #2fa735;
  border-color: #42ca49;
}
.btn-g:not(:disabled):not(.disabled):active {
  background-color: #42ca49;
}
.btn-g:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,90,90,0.5);
}
.btn-xlg {
  padding: 0.7rem 4rem;
  line-height: 1.8;
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
}
.has-icon {
  position: relative;
  display: inline;
  line-height: 1.2;
  padding-right: 19px;
}
