.layout
    .dropdown-menu
    .dropmenu-first
        border none
    main
        +below(1024px)
            padding-top 20px
    .slice-cinza
        background cinza
    .slice
        padding-top 20px
        padding-bottom 20px
        .subtitle
            margin-top: 0
            margin-bottom: 40px
            font-size: 25px
            color colorp
            font-weight: normal
            text-transform uppercase
            text-align: center
    ul.breadcrumbs
        padding 0
        margin-bottom 1em
        color colors
        li:not(:last-child)
            &:after
                content '>>'
        li:last-child
            text-decoration underline
        li
            display inline-block
            text-transform uppercase
            i.fas
                color colors
            a
                font-size 12px
                color colorp
                text-transform uppercase
                text-decoration: none
    .share-w
        display flex
        align-items: center
        padding 22px 0 0
        .share-txt, .share-icons
            display inline-block
        .share-txt
            margin-right 15px
            font-size 15px
        .share-icons
            .share-item
                cursor pointer
                i.fab, i.fas
                    font-size 34px
            .share-facebook
                i.fab
                    color #3A5B9A
            .share-twitter
                i.fab
                    color #55A4DB
            .share-google-plus
                i.fab
                    color #E04D41
            .share-mail
                i.fas
                    color #033696
