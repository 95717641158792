.btn
    font-size 13px
    &:focus
        box-shadow: none!important
.btn.btn-100
    width 100%
    margin 0 0 11px 0
.btn-primary
    color:#fff
    background-color:colorp
    border-color:colorp
    &:hover
        background-color:colorp - 20%
        border-color:colorp
    &:not(:disabled):not(.disabled):active
        background-color:colorp
    &:not(:disabled):not(.disabled):active:focus
        box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)

.btn-secondary
    color:#fff
    background-color:colors
    border-color:colors
    &:hover
        background-color:colors - 20%
        border-color:colors
    &:not(:disabled):not(.disabled):active
        background-color:colors
    &:not(:disabled):not(.disabled):active:focus
        box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)

.btn-tertiary
    color:#fff
    background-color:colort
    border-color:colort
    &:hover
        background-color:colort - 20%
        border-color:colort
    &:not(:disabled):not(.disabled):active
        background-color:colort
    &:not(:disabled):not(.disabled):active:focus
        box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)

.btn-g
    color:#fff
    background-color:colorg
    border-color:colorg
    &:hover
        background-color:colorg - 20%
        border-color:colorg
    &:not(:disabled):not(.disabled):active
        background-color:colorg
    &:not(:disabled):not(.disabled):active:focus
        box-shadow:0 0 0 .2rem rgba(0,90,90,0.5)

.btn-xlg
    padding .7rem 4rem
    line-height 1.8
    font-size 19px
    font-weight: bold
    text-transform uppercase

.has-icon
    position relative
    display inline
    line-height: 1.2
    padding-right 19px

