.down-arrow
    display: inline-block
    position: absolute
    right: 6px
    top: 6px
    background: colors
    padding: 0
    width: 10px
    text-align: center
    &:after
        content: ''
        display: block
        position: absolute
        width: 0
        height: 0
        border-top: 5px solid colors
        border-right: 6px solid transparent
        border-bottom: 0 solid transparent
        border-left: 6px solid transparent


.data-block.data-warning
    background-color #FFF0F0
    color colorr
    p, p>strong
        color colorr
.data-block.data-info
    background-color #F4FAFF
    color: #0D7FCC
    p, p>strong
        color #0D7FCC
.data-block
    position: relative
    font-size: 14px
    lost-center 90%
    max-width 860px
    border-radius radius
    background-color #FCF8E3
    border: 1px solid #6D6D6D
    padding 23px 15px 24px 10px
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3)
    ul
        text-align: left
        padding-left 22px
    i.icon
        display inline-block
        top -4px
        float left
    .block-title
        font-weight normal
        font-size 16px
        color #FF3535
        margin-bottom 10px
    p
        color: #717272
        font-size 14px
        font-weight lighter
        margin 0 0 0 auto
        padding 0 24px 0 35px
    a
        background: #044a90
        color white
    .block-close
        position absolute
        top 0
        right 0
        display block
        overflow visible
        width 35px
        height 35px
        margin 0
        padding 0
        color #95979c
        cursor pointer
        transition color 0.2s
        border 0
        outline 0
        background transparent
    .block-close:before
        position absolute
        font-size 25px
        line-height 35px
        top 0
        left 0
        display block
        width 35px
        content "\00d7"
        text-align center